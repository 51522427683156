import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { of, tap } from 'rxjs';

declare var gigya: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  expirationTime: any;
  accessToken: any;
  constructor(private http: HttpClient) {}

  getProfileDetails() {
    const profileDetails = JSON.parse(
      sessionStorage.getItem('profile') as string
    );
    return profileDetails;
  }

  setAuthorize(params: any) {
    sessionStorage.setItem('isAuthorized', params);
  }

  handleToken(data: any) {
    this.saveTokens(data);
  }

  redirectToURL(path: string) {
    window.location.href = window.location.origin + `/${path}`;
  }

  getToken(code: any) {
    const {
      issuer_url,
      apiKey,
      grant_type,
      redirect_uri,
      clientId,
      clientSecret,
    } = environment;
    const headers = new HttpHeaders({
      Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
    });

    this.http
      .post(
        `${issuer_url}/${apiKey}/token?grant_type=${grant_type}&redirect_uri=${redirect_uri}&code=${code}`,
        {},
        {
          headers: headers,
        }
      )
      .subscribe((data: any) => {
        if (data?.access_token) {
          this.saveTokens(data);
          // this.accessToken = data.access_token;
          // this.handleToken(data?.data);
          this.redirectToURL('');
        }
      });
  }

  // fetchRefreshToken() {
  //   const refreshToken: any = sessionStorage.getItem('refreshToken');
  //   const headers = new HttpHeaders({
  //     refreshToken: refreshToken,
  //     redirectUri: environment.redirect_url,
  //   });
  //   return this.http
  //     .get(`${environment.base_url}token`, {
  //       headers: headers,
  //     })
  //     .pipe(
  //       map((data: any) => {
  //         if (data?.data.access_token) {
  //           this.handleToken(data?.data);
  //         }
  //         return data?.data;
  //       })
  //     );
  // }

  saveTokens(tokendata: any) {
    sessionStorage.setItem('accessToken', tokendata.access_token);
    sessionStorage.setItem('refreshToken', tokendata.refresh_token);
  }

  logOut() {
    gigya.accounts.logout({
      callback: (response: any) => {
        if (response.errorCode === 0) {
          sessionStorage.clear();
          this.redirectToURL('login');
        }
      },
    });
  }
}
